<template>
    <div>
        <!-- Table Container Card -->
        <h4 class="font-weight-bolder">Next Final Trends List</h4>
        <b-card no-body class="mb-0">
            <b-overlay :show="show" rounded="sm" no-fade>
                <b-tabs pills align="center" class="mt-3" nav-class="nav-pill-danger">
                    <b-tab title="Artists" active>
                        <div class="container">
                            <draggable v-model="typeList[0].table" class="list-group list-group-flush cursor-move" tag="ul">
                                <transition-group type="transition" name="flip-list">
                                    <b-list-group-item v-for="listItem in typeList[0].table" :key="listItem.id" tag="li">
                                        <div class="d-flex">
                                            <h5 class="mt-2">
                                                <b-avatar variant="light-success"> {{ listItem.rank }} </b-avatar>
                                            </h5>
                                            <div class="col-md-3 d-flex flex-column align-items-center">
                                                <b-avatar :src="listItem.trend_detail.app_thumbnail" />
                                                <span class="text-dark">{{ listItem.trend_detail.name }}</span>
                                            </div>
                                            <div class="col-md-3 d-flex flex-column">
                                                <small class="text-danger">Email</small>
                                                <span class="mt-1">{{ listItem.trend_detail.email }}</span>
                                            </div>
                                            <div class="col-md-3 d-flex flex-column">
                                                <small class="text-danger">Date</small>
                                                <span class="mt-1">{{ listItem.date }}</span>
                                            </div>
                                            <div class="col-md-3 d-flex flex-column">
                                                <small class="text-danger">Views</small>
                                                <span class="mt-1">{{ listItem.last_n_days_views }}</span>
                                            </div>
                                        </div>
                                    </b-list-group-item>
                                </transition-group>
                            </draggable>
                            <b-row class="justify-content-center my-2">
                                <b-button variant="gradient-success" @click="updateHandler(0)">
                                    <feather-icon icon="ActivityIcon" class="mr-50" />
                                    <span class="align-middle">Update</span>
                                </b-button>
                            </b-row>
                        </div>
                    </b-tab>
                    <b-tab title="Musics">
                        <div class="container">
                            <draggable v-model="typeList[1].table" class="list-group list-group-flush cursor-move" tag="ul">
                                <transition-group type="transition" name="flip-list">
                                    <b-list-group-item v-for="listItem in typeList[1].table" :key="listItem.id" tag="li">
                                        <div class="d-flex">
                                            <h5 class="mt-2">
                                                <b-avatar variant="light-success"> {{ listItem.rank }} </b-avatar>
                                            </h5>
                                            <div class="col-md-3 d-flex flex-column align-items-center">
                                                <b-avatar :src="listItem.trend_detail.app_thumbnail" />
                                                <span class="text-dark">{{ listItem.trend_detail.name }}</span>
                                            </div>
                                            <div class="col-md-3 d-flex flex-column">
                                                <small class="text-danger">Email</small>
                                                <span class="mt-1">{{ listItem.trend_detail.email }}</span>
                                            </div>
                                            <div class="col-md-3 d-flex flex-column">
                                                <small class="text-danger">Date</small>
                                                <span class="mt-1">{{ listItem.date }}</span>
                                            </div>
                                            <div class="col-md-3 d-flex flex-column">
                                                <small class="text-danger">Views</small>
                                                <span class="mt-1">{{ listItem.last_n_days_views }}</span>
                                            </div>
                                        </div>
                                    </b-list-group-item>
                                </transition-group>
                            </draggable>
                            <b-row class="justify-content-center my-2">
                                <b-button variant="gradient-success" @click="updateHandler(1)">
                                    <feather-icon icon="ActivityIcon" class="mr-50" />
                                    <span class="align-middle">Update</span>
                                </b-button>
                            </b-row>
                        </div>
                    </b-tab>
                    <b-tab title="Videos">
                        <div class="container">
                            <draggable v-model="typeList[2].table" class="list-group list-group-flush cursor-move" tag="ul">
                                <transition-group type="transition" name="flip-list">
                                    <b-list-group-item v-for="listItem in typeList[2].table" :key="listItem.id" tag="li">
                                        <div class="d-flex">
                                            <h5 class="mt-2">
                                                <b-avatar variant="light-success"> {{ listItem.rank }} </b-avatar>
                                            </h5>
                                            <div class="col-md-3 d-flex flex-column align-items-center">
                                                <b-avatar :src="listItem.trend_detail.app_thumbnail" />
                                                <span class="text-dark">{{ listItem.trend_detail.name }}</span>
                                            </div>
                                            <div class="col-md-3 d-flex flex-column">
                                                <small class="text-danger">Email</small>
                                                <span class="mt-1">{{ listItem.trend_detail.email }}</span>
                                            </div>
                                            <div class="col-md-3 d-flex flex-column">
                                                <small class="text-danger">Date</small>
                                                <span class="mt-1">{{ listItem.date }}</span>
                                            </div>
                                            <div class="col-md-3 d-flex flex-column">
                                                <small class="text-danger">Views</small>
                                                <span class="mt-1">{{ listItem.last_n_days_views }}</span>
                                            </div>
                                        </div>
                                    </b-list-group-item>
                                </transition-group>
                            </draggable>
                            <b-row class="justify-content-center my-2">
                                <b-button variant="gradient-success" @click="updateHandler(2)">
                                    <feather-icon icon="ActivityIcon" class="mr-50" />
                                    <span class="align-middle">Update</span>
                                </b-button>
                            </b-row>
                        </div>
                    </b-tab>
                </b-tabs>
            </b-overlay>
        </b-card>
    </div>
</template>

<script>
import { BOverlay, BTabs, BTab, BCard, BRow, BCol, BFormInput, BFormSelect, BButton, BTable, BMedia, BAvatar, BLink, BBadge, BDropdown, BDropdownItem, BPagination, BFormDatepicker, BListGroupItem } from "bootstrap-vue";
import vSelect from "vue-select";
import { ref, onUnmounted, onMounted } from "@vue/composition-api";
import useTrendingList from "./useTrendingList";
import draggable from "vuedraggable";
import Ripple from "vue-ripple-directive";
import Prism from "vue-prism-component";
import "prismjs";
import "prismjs/themes/prism-tomorrow.css";
export default {
    directives: {
        Ripple,
    },
    components: {
        draggable,
        BListGroupItem,
        BCard,
        BRow,
        BCol,
        BFormInput,
        BFormSelect,
        BButton,
        BTable,
        BMedia,
        BAvatar,
        BLink,
        BBadge,
        BDropdown,
        BDropdownItem,
        BPagination,
        BTabs,
        BTab,
        vSelect,
        BFormDatepicker,
        BOverlay,
        Prism,
    },
    setup() {
        const { typeList, type, fetchNextFinalTrends, updateNextFinalTrends } = useTrendingList();
        const choosedTab = ref("artists");
        const show = ref(false);
        onMounted(async () => {
            for (let i = 0; i < typeList.value.length; i++) {
                type.value = typeList.value[i].type;
                await fetchNextFinalTrends().then((res) => {
                    typeList.value[i].table = res.data;
                    console.log(typeList.value[i].table);
                });
            }
        });
        
        const updateHandler = (i) => {
            console.log(typeList.value[i].table);
            let updateData = typeList.value[i].table.map((item, index) => {
                let data = {
                    id: item.id,
                    rank: index + 1,
                };
                return data;
            });
            let data = {
                type: typeList.value[i].type,
                trends: updateData,
            };
            updateNextFinalTrends(data).then((res) => {
                console.log(res);
            });
        };
        return {
            show,
            choosedTab,
            typeList,
            updateHandler,
        };
    },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
    width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

a {
    color: $red;
}
</style>
